import { createStore } from 'satcheljs';

import { INotificationStore } from './notification.type';

const initStore: INotificationStore = {
  unreadNotificationQuantity: undefined,
};

export const getNotificationStore = createStore(
  'notification-store',
  initStore
);
