export enum ENotificationIsReadStatus {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export interface INotification {
  id?: string;
  subject?: string;
  description?: string;
  content?: string;
  category?: string;
  createdAt?: string;
  isRead?: ENotificationIsReadStatus;
}
