import { API } from '@totopkg/shared-util-api';
import { TSearchParams, TSearchResponse } from '@totopkg/shared-util-crud';

import { INotification } from '../type';

export const getNotificationsApi = (params?: TSearchParams<INotification>) => {
  return new Promise<TSearchResponse<INotification>>((resolve, reject) => {
    API.get<INotification[]>('/notification/u/notifications', {
      params,
    })
      .then((response) => resolve({ data: response.data }))
      .catch(reject);
  });
};
