import { orchestrator } from 'satcheljs';

import { getUnreadNotificationQuantityAction } from '../action';
import { getUnreadNotificationCountApi } from '../api';
import { setUnreadNotificationQuantityAction } from '../mutator-action';

orchestrator(getUnreadNotificationQuantityAction, (actionMessage) => {
  const { callback } = actionMessage;

  getUnreadNotificationCountApi()
    .then((response) => {
      setUnreadNotificationQuantityAction(response);
      callback?.success?.(response);
    })
    .catch((error) => callback?.error?.(error))
    .finally(() => callback?.finally?.());
});
