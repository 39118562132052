import { mutatorAction } from 'satcheljs';

import { getNotificationStore } from '../store/notification.store';

export const setUnreadNotificationQuantityAction = mutatorAction(
  'setUnreadNotificationQuantityAction',
  (quantity: number | undefined) => {
    getNotificationStore().unreadNotificationQuantity = quantity;
  }
);
