import { TActionCallback } from '@totopkg/shared-util-common';
import { CrudStore } from '@totopkg/shared-util-crud';

import { getUnreadNotificationQuantityAction } from '../action';
import { getNotificationsApi, postNotificationReadApi } from '../api';
import { setUnreadNotificationQuantityAction } from '../mutator-action';
import { unreadNotificationQuantitySelector } from '../selector';
import { INotification } from '../type';

enum ENotiAction {
  READ = 'READ',
}

export class Notification extends CrudStore.BaseCrudData<
  INotification,
  INotification
> {
  private static _instance: Notification;

  private constructor() {
    super();
  }

  public static instance(): Notification {
    if (!this._instance) {
      this._instance = new Notification();
    }

    return this._instance;
  }

  protected builder(): void {
    this.config({
      search: {
        default: {
          search: getNotificationsApi,
          option: {
            defaultSearchParams: {
              page: 1,
              size: 10,
            },
          },
        },
      },

      update: {
        default: undefined,
        [ENotiAction.READ]: (id, data) =>
          postNotificationReadApi(id?.toString(), data),
      },
    });
  }

  public fetchUnreadNotiQuantity = (
    ...params: Parameters<typeof getUnreadNotificationQuantityAction>
  ) => {
    getUnreadNotificationQuantityAction(...params);
  };

  public getUnreadNotiQuantity = () => {
    return unreadNotificationQuantitySelector();
  };

  public readNotification = (
    id: INotification['id'],
    data: INotification | undefined,
    options?: {
      callback?: TActionCallback<INotification>;
    }
  ) => {
    this.updateItem(id, data, {
      functionGroupId: ENotiAction.READ,
      ...options,
      callback: {
        ...options?.callback,
        success: () => {
          const unreadNoti = unreadNotificationQuantitySelector();
          !!unreadNoti && setUnreadNotificationQuantityAction(unreadNoti - 1);
          options?.callback?.success?.();
        },
      },
    });
  };
}
