import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

export const getUnreadNotificationQuantityAction = action(
  'getUnreadNotificationQuantityAction',
  (callback?: TActionCallback<number>) => {
    return { callback };
  }
);
